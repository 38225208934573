<template>
  <v-container fluid class="fill-height" v-bind:style="backgroundDefault">
    <v-row class="d-flex flex-column fill-height mb-14">
      <div class="pa-md-2 mb-14 fill-height">
        <v-layout class="ma-0 pa-0">
          <v-app-bar rounded class="justify-center" v-bind:style="catalogBgFolorTitle">
            <v-app-bar-title class="mt-5 pt-0">
              <p
                style="width: 300px"
                v-html="
                  this.$appConfig.homeTitle.find(
                    (_) => _.culture === $i18n.locale
                  ).name
                "
              ></p>
            </v-app-bar-title>
            <v-spacer> </v-spacer>

            <v-select
              v-show="$appConfig.langs.length > 1"
              v-if="$vuetify.breakpoint.smAndDown ? false : true"
              class="mt-7 pt-0"
              background-color="primary"
              outlined
              dense
              :rounded="$appConfig.textbox.rounded"
              dark
              style="width: 100px; max-width: 200px"
              :items="$appConfig.langs"
              item-text="name"
              item-value="id"
              v-model="$i18n.locale"
            >
              <template slot="prepend-inner">
                <v-icon>mdi-earth</v-icon>
              </template>
            </v-select>

            <div
              class="pr-4"
              v-if="$vuetify.breakpoint.smAndDown ? true : false"
              v-show="$appConfig.langs.length > 1"
            >
              <v-menu top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    small
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-earth </v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, id) in $appConfig.langs"
                    :key="id"
                    @click="langClick(item.id)"
                  >
                    <v-list-item-title style="cursor: pointer">{{
                      item.name
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-app-bar>
        </v-layout>

        <v-layout
          row
          wrap
          class="d-flex flex-column ml-0 mr-0 mt-4 pa-md-2 fill-height"
          v-bind:style="catalogBgFolor"
        >
          <div>
            <v-row class="mt-2 ml-2">
              <v-col class="col-12 col-lg-2 p-2">
                <v-btn
                  class="justify-center"
                  small
                  color="primary"
                  :rounded="
                    $appConfig.hasOwnProperty('button')
                      ? $appConfig.button.rounded
                      : false
                  "
                  width="120px"
                  @click="goBack"
                >
                  <v-icon left class="ml-0"> mdi-arrow-left-circle </v-icon>
                  {{ $t("common.goBack") }}
                </v-btn>
              </v-col>
 
              <v-col  class="col-12 col-lg-8 pl-5 pr-5 pt-0 pb-0 text-center">
                <div v-if="!showProductTitle" v-bind:style="catalogColorTitle"  v-html="$t('catalog.title')"></div>
                <div v-if="showProductTitle"  v-bind:style="catalogColorTitle" v-html="this.$appConfig.titleProduct.find((_) => _.langId === $i18n.locale).messageTitle"></div>
         
              </v-col>
              <!--  <div :hidden="$vuetify.breakpoint.smAndDown ? true : false">
                      <h3 class="ma-2">{{ $t("common.filterLabel") }}</h3>
                    </div> -->
              <v-col class="col-12 col-lg-2 p-2 text-end">
                <v-badge
                  :content="filtersCount"
                  :value="filtersCount"
                  color="green"
                  overlap
                  dark
                  class="mr-5"
                >
                  <v-btn fab dark color="white">
                    <v-icon
                      color="primary"
                      dark
                      @click="
                        openedPanel == 0
                          ? (openedPanel = null)
                          : (openedPanel = 0)
                      "
                    >
                      mdi-filter
                    </v-icon>
                  </v-btn>
                </v-badge>
              </v-col>
            </v-row>

            <v-expansion-panels v-model="openedPanel" flat>
              <v-expansion-panel class="transparent">
                <!--  <v-expansion-panel-header disable-icon-rotate>
              </v-expansion-panel-header> -->
                <v-expansion-panel-content class="white">
                  <v-layout fill-height column align-end class="pa-2">
                    <v-layout row class="mt-2">
                      <v-text-field
                        v-model="searchFilter"
                        :placeholder="$t('catalog.searchPlaceHolder')"
                        clearable
                        filled
                        :rounded="
                          $appConfig.hasOwnProperty('button')
                            ? $appConfig.button.rounded
                            : false
                        "
                        dense
                        :label="$t('common.search')"
                        prepend-inner-icon="mdi-magnify"
                        style="width: 324px"
                      ></v-text-field>
                    </v-layout>
                    <v-layout row>
                      <v-select
                        v-model="familyFilter"
                        :items="catalogfamilyFilter"
                        item-text="family"
                        item-value="familyId"
                        v-bind:label="$t('catalog.familyFilterName')"
                        chips
                        multiple
                        clearable
                        style="width: 324px"
                      ></v-select>
                    </v-layout>

                    <v-row style="width: 324px">
                      <v-btn
                        :rounded="
                          $appConfig.hasOwnProperty('button')
                            ? $appConfig.button.rounded
                            : false
                        "
                        color="primary"
                        dark
                        v-on:click="filter"
                      >
                        <v-icon> mdi-filter </v-icon>
                        {{ $t("common.filter") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        dark
                        :rounded="
                          $appConfig.hasOwnProperty('button')
                            ? $appConfig.button.rounded
                            : false
                        "
                        v-on:click="clearFilter"
                      >
                        <v-icon> mdi-filter-off </v-icon>
                        {{ $t("common.filterClear") }}
                      </v-btn>
                    </v-row>
                  </v-layout>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
     
          <v-row v-if="this.isLoading == true" class="ma-0 pa-0 justify-center">
            <v-flex v-for="n in 7" :key="n">
              <v-skeleton-loader
                v-bind="attrs"
                class="mx-auto mt-5 mb-2"
                max-width="344"
                min-width="344"
                height="400"
                max-height="500"
                type="card, article, actions"
              ></v-skeleton-loader>
            </v-flex>
          </v-row>
          <v-row
            class="ma-0 pa-0 justify-center"
            v-if="this.isLoading == false"
          >
            <v-flex
              v-for="(item, productCode) in filteredCatalog"
              :key="productCode"
            >
              <v-card
                flat
                hover
                class="mx-auto mt-5 mb-2"
                max-width="344"
                min-width="344"
                height="400"
                max-height="500"
              >
                <!--  <template slot="progress">
                    <v-progress-linear
                      color="deep-purple"
                      height="10"
                      indeterminate
                    ></v-progress-linear>
                  </template> -->

                <v-hover style="background-color: white">
                  <template
                    v-slot:default="{ hover }"
                    transition="slide-x-transition"
                  >
                    <v-img height="250" contain v-bind:src="item.imageUrl">
                      <v-fade-transition>
                        <v-overlay
                          v-if="hover"
                          absolute
                          :color="$appConfig.bgHover"
                        >
                          <template>
                            <v-btn
                              :rounded="
                                $appConfig.hasOwnProperty('button')
                                  ? $appConfig.button.rounded
                                  : false
                              "
                              color="primary"
                              dark
                              @click.stop="
                                {
                                  selectedProduct = item;
                                  dialog = true;
                                }
                              "
                            >
                              {{ $t("catalog.details") }}
                            </v-btn>
                          </template>
                        </v-overlay>
                      </v-fade-transition>
                    </v-img>
                  </template>
                </v-hover>

                <v-card-title style="font-size: 1rem;"  class="justify-center primary--text">
                  {{
                    item.name.length > 60
                      ? item.name.slice(0, 60) + "..."
                      : item.name
                  }}
                </v-card-title>

                <v-card-actions class="justify-center">
                  <template>
                    <v-btn
                      color="primary"
                      min-width="300px"
                      :rounded="
                        $appConfig.hasOwnProperty('button')
                          ? $appConfig.button.rounded
                          : false
                      "
                      @click="redeem(item)"
                    >
                      {{ $t("catalog.productRedeem") }}
                    </v-btn>
                  </template>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-row>

          <v-dialog max-width="600" v-model="dialog">
            <template>
              <v-card>
                <v-toolbar color="primary" dark>{{
                  selectedProduct.name
                }}</v-toolbar>
                <v-card-text>
                  <v-img
                    height="250"
                    class="ma-6"
                    contain
                    v-bind:src="selectedProduct.imageUrl"
                  >
                  </v-img>
                  <v-row justify="end" class="mx-0">
                    <div class="my-4 subtitle">
                      REF: {{ selectedProduct.productCode }}
                    </div>
                  </v-row>

                <p class="my-4 subtitle">
                     {{ selectedProduct.description }}
                    </p>
                
                  
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn
                    color="primary"
                    :rounded="
                      $appConfig.hasOwnProperty('button')
                        ? $appConfig.button.rounded
                        : false
                    "
                    @click="dialog = false"
                  >
                    {{ $t("common.close") }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-layout>

        <FlashMessage :position="'right top'"></FlashMessage>
      </div>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import buildQuery from "odata-query";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import $ from "jquery";

export default {
  name: "Catalog",
  data() {
    return {
      fingerPrint: "",
      code: "",
      isLoading: true,
      openedPanel: null,
      dialog: false,
      attrs: "",
      rules: {
        required: (value) => !!value || this.$t("common.required"),
      },
      apiErrorMessage: [],
      pageSize: 10,
      page: 1,
      odataFilter: "",
      selectedProduct: {
        productCode: "",
        clientProductCode: "",
        productSetId: "",
        name: "",
        description: "",
        familyId: "",
        stock: 0,
        points: 0,
        imageUrl: "",
        isVoucher: null,
      },
      catalog: [],
      catalogfamilyFilter: [],
      filteredCatalog: [],
      productTitle:"",
      showProductTitle:false,
      familyFilter: [],
      searchFilter: "",
      familyFilterMaster: []
    };
  },
  mounted() {
    this.loadData();  
    this.loadCatalogTitle();
    //this.$appInsights.trackPageView({ name: "Catalog" });
    this.getFamilyMaster();
  },

  beforeMount() {
    this.isLoading = true;
    this.loadCatalogTitle()
  },

  methods: {
    loadCatalogTitle() {
      if (this.$appConfig.activeTitleProduct != null || this.$appConfig.showEmailDefault != undefined) {
          if (this.$appConfig.activeTitleProduct == true) {
            this.showProductTitle = this.$appConfig.activeTitleProduct;
          } 
      }
    },
    async loadData() {
      await Promise.all([
        this.CheckCatalogToken(),
        this.GetCatalog(),
        this.GetFingerPrint(),
      ])
        .then(() => {})
        .finally(() => {
          //this.isLoading = false;
        });
    },
    async GetFingerPrint() {
      // Initialize an agent at application startup.
      const fpPromise = FingerprintJS.load();

      (async () => {
        // Get the visitor identifier when you need it.
        const fp = await fpPromise;
        const result = await fp.get();

        // This is the visitor identifier:
        const visitorId = result.visitorId;

        this.fingerPrint = visitorId;
      })();
    },
    async CheckCatalogToken() {
      if (this.$store.state.catalogToken == "") {
        this.$appInsights.trackEvent({
          name: "frontend-check-catalogtoken-failed",
          properties: {
            fingerprint: this.fingerPrint,
          },
        });
        this.$router.push("home");
      }
    },
    async GetCatalog() {
      this.isLoading = true;
      if (this.$store.state.catalogToken != "") {
        this.$recaptcha("GetCatalog").then((token) => {
          //console.log(token);
          axios
            .get(this.apiurl, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Accept-Language": this.$i18n.locale,
                "Finger-Print": this.fingerPrint,
                "catalog-token": this.$store.state.catalogToken,
                "Recaptcha-Token": token,
                "x-functions-key":
                  process.env.VUE_APP_API_PRODUCTSET_FUNCTION_KEY,
                "ai-operation-id":
                  this.$appInsights.context.telemetryTrace.traceID,
                "ai-session-id":
                  this.$appInsights.context.sessionManager.automaticSession.id,
              },
            })
            .then((response) => {
              this.catalog = response.data;
              this.filter();
              this.$appInsights.trackEvent({
                name: "frontend-get-catalogtoken-success",
                properties: {
                  fingerprint: this.fingerPrint,
                },
              });
            })
            .catch((error) => {
              if (error.response) {
                this.$appInsights.trackEvent({
                  name: "frontend-get-catalogtoken-success",
                  properties: {
                    fingerprint: this.fingerPrint,
                    status: error.response.status,
                  },
                });
                switch (error.response.status) {
                  case 409:
                  case 404:
                    error.response.data.forEach((element) => {
                      this.flashMessage.info({
                        title: this.$t("common.crudinfo"),
                        message: element.value,
                        contentClass: "customMsgContentClass",
                      });
                    });

                    break;
                  case 423:
                    error.response.data.forEach((element) => {
                      this.flashMessage.warning({
                        title: this.$t("common.crudinfo"),
                        message: element.value,
                        contentClass: "customMsgContentClass",
                      });
                    });

                    break;

                  default:
                    error.response.data.forEach((element) => {
                      this.flashMessage.error({
                        title: this.$t("common.cruderror"),
                        message: element.value,
                        contentClass: "customMsgContentClass",
                      });
                    });
                    break;
                }
              }
              //console.clear();
            })
            .finally(() => {
              this.isLoading = false;
            });
        });
      }
    },

    buildFilter() {
      const page = this.page;
      const perPage = this.pageSize;
      const top = perPage;
      const skip = perPage * (page - 1);
      const filter = {
        or: [
          { name: { contains: this.searchFilter.toLowerCase() ?? "" } },
          { description: { contains: this.searchFilter.toLowerCase() ?? "" } },
        ],
        familyId: {
          in:
            this.familyFilter.length > 0
              ? this.familyFilter
              : this.familyFilterMaster.map((a) => a.familyId),
        },
      };

      const orderBy = ["name"];
      this.odataFilter = buildQuery({ filter, top, skip, orderBy });
    },
    filter() {
      /*  this.catalog.shift();
      this.odataFilter = "";
      this.page = 1;
      this.GetCatalog(); */
      this.catalogfamilyFilter = this.familyFilterMaster.filter(x => this.catalog.find( _ => x.familyId == _.familyId));
  
      this.filteredCatalog = this.catalog.filter(
        (_) =>
          (_.name
            .toLowerCase()
            .includes(this.searchFilter?.toLowerCase() ?? "") ||
            _.description
              .toLowerCase()
              .includes(this.searchFilter?.toLowerCase() ?? "")) &&
          (this.familyFilter.length > 0
            ? this.familyFilter
            : this.familyFilterMaster.map((a) => a.familyId)
          ).includes(_.familyId) &&( _.parentProduct == "" || _.parentProduct == undefined)
      );
    },
    clearFilter() {
      this.familyFilter = [];
      this.searchFilter = "";
      this.filteredCatalog = this.catalog;
    },
    redeem(item) {
      this.$store.dispatch("updateSelectedProduct", item);      
      Object.assign(this.selectedProduct, item);

      this.$router.push("redeem");
    },
    goBack() {
      this.$router.back();
    },
    langClick(id) {
      this.$i18n.locale = id;
    },
    getFamilyMaster() {
      const self = this;
      $.getJSON(process.env.VUE_APP_CTC_MASTERS_PATH + process.env.VUE_APP_CTC_MASTERS_FAMILY_FILE_NAME.replace("{{lang}}", self.$i18n.locale),
        function (json) {
          self.familyFilterMaster = json;
        }
      );
    }
  },
  computed: {
    apiurl() {
      // this.buildFilter();
      //return process.env.VUE_APP_API_HOST + "/ProductSet" + this.odataFilter;
      return process.env.VUE_APP_API_HOST + "/ProductSet";
    },
    filtersCount() {
      return (
        (this.familyFilter?.length > 0 ? 1 : 0) +
        (this.searchFilter?.length > 0 ? 1 : 0)
      );
    },
    
    catalogColorTitle(){

      return{
        "color" : this.$appConfig.titleCatalog, 
        "text-align": "center",
      };
    },
   
    backgroundDefault: function () {
      return {
        "background-image":
          "url(" + this.$appConfig.catalogBackGroundImage + " )",
        "background-position": "center 0",
        "background-size": "100%",
        "min-width": "344px",
        "background-repeat": "no-repeat",
        "background-attachment": "fixed"
      };
    },

    catalogBgFolor: function () {
      return {
        "border-radius": "5px",
        "background-color": this.$appConfig.transparentBgColor,
      };
    },
    catalogBgFolorTitle: function () {
      let result;

      if( this.$appConfig.homeTitle.find((_) => _.culture === this.$i18n.locale).name.length===0)  {
        result =  "background-color: transparent !important"   
       }
      return result;
    }, 
  },
  watch: {
    "$i18n.locale": function () {
      this.GetCatalog();
    },
  },
};
</script>
