var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"fill-height",style:(_vm.backgroundDefault),attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex flex-column fill-height mb-14"},[_c('div',{staticClass:"pa-md-2 mb-14 fill-height"},[_c('v-layout',{staticClass:"ma-0 pa-0"},[_c('v-app-bar',{staticClass:"justify-center",style:(_vm.catalogBgFolorTitle),attrs:{"rounded":""}},[_c('v-app-bar-title',{staticClass:"mt-5 pt-0"},[_c('p',{staticStyle:{"width":"300px"},domProps:{"innerHTML":_vm._s(
                this.$appConfig.homeTitle.find(
                  (_) => _.culture === _vm.$i18n.locale
                ).name
              )}})]),_c('v-spacer'),(_vm.$vuetify.breakpoint.smAndDown ? false : true)?_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.$appConfig.langs.length > 1),expression:"$appConfig.langs.length > 1"}],staticClass:"mt-7 pt-0",staticStyle:{"width":"100px","max-width":"200px"},attrs:{"background-color":"primary","outlined":"","dense":"","rounded":_vm.$appConfig.textbox.rounded,"dark":"","items":_vm.$appConfig.langs,"item-text":"name","item-value":"id"},model:{value:(_vm.$i18n.locale),callback:function ($$v) {_vm.$set(_vm.$i18n, "locale", $$v)},expression:"$i18n.locale"}},[_c('template',{slot:"prepend-inner"},[_c('v-icon',[_vm._v("mdi-earth")])],1)],2):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown ? true : false)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$appConfig.langs.length > 1),expression:"$appConfig.langs.length > 1"}],staticClass:"pr-4"},[_c('v-menu',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-earth ")])],1)]}}],null,false,2157092069)},[_c('v-list',_vm._l((_vm.$appConfig.langs),function(item,id){return _c('v-list-item',{key:id,on:{"click":function($event){return _vm.langClick(item.id)}}},[_c('v-list-item-title',{staticStyle:{"cursor":"pointer"}},[_vm._v(_vm._s(item.name))])],1)}),1)],1)],1):_vm._e()],1)],1),_c('v-layout',{staticClass:"d-flex flex-column ml-0 mr-0 mt-4 pa-md-2 fill-height",style:(_vm.catalogBgFolor),attrs:{"row":"","wrap":""}},[_c('div',[_c('v-row',{staticClass:"mt-2 ml-2"},[_c('v-col',{staticClass:"col-12 col-lg-2 p-2"},[_c('v-btn',{staticClass:"justify-center",attrs:{"small":"","color":"primary","rounded":_vm.$appConfig.hasOwnProperty('button')
                    ? _vm.$appConfig.button.rounded
                    : false,"width":"120px"},on:{"click":_vm.goBack}},[_c('v-icon',{staticClass:"ml-0",attrs:{"left":""}},[_vm._v(" mdi-arrow-left-circle ")]),_vm._v(" "+_vm._s(_vm.$t("common.goBack"))+" ")],1)],1),_c('v-col',{staticClass:"col-12 col-lg-8 pl-5 pr-5 pt-0 pb-0 text-center"},[(!_vm.showProductTitle)?_c('div',{style:(_vm.catalogColorTitle),domProps:{"innerHTML":_vm._s(_vm.$t('catalog.title'))}}):_vm._e(),(_vm.showProductTitle)?_c('div',{style:(_vm.catalogColorTitle),domProps:{"innerHTML":_vm._s(this.$appConfig.titleProduct.find((_) => _.langId === _vm.$i18n.locale).messageTitle)}}):_vm._e()]),_c('v-col',{staticClass:"col-12 col-lg-2 p-2 text-end"},[_c('v-badge',{staticClass:"mr-5",attrs:{"content":_vm.filtersCount,"value":_vm.filtersCount,"color":"green","overlap":"","dark":""}},[_c('v-btn',{attrs:{"fab":"","dark":"","color":"white"}},[_c('v-icon',{attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.openedPanel == 0
                        ? (_vm.openedPanel = null)
                        : (_vm.openedPanel = 0)}}},[_vm._v(" mdi-filter ")])],1)],1)],1)],1),_c('v-expansion-panels',{attrs:{"flat":""},model:{value:(_vm.openedPanel),callback:function ($$v) {_vm.openedPanel=$$v},expression:"openedPanel"}},[_c('v-expansion-panel',{staticClass:"transparent"},[_c('v-expansion-panel-content',{staticClass:"white"},[_c('v-layout',{staticClass:"pa-2",attrs:{"fill-height":"","column":"","align-end":""}},[_c('v-layout',{staticClass:"mt-2",attrs:{"row":""}},[_c('v-text-field',{staticStyle:{"width":"324px"},attrs:{"placeholder":_vm.$t('catalog.searchPlaceHolder'),"clearable":"","filled":"","rounded":_vm.$appConfig.hasOwnProperty('button')
                          ? _vm.$appConfig.button.rounded
                          : false,"dense":"","label":_vm.$t('common.search'),"prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.searchFilter),callback:function ($$v) {_vm.searchFilter=$$v},expression:"searchFilter"}})],1),_c('v-layout',{attrs:{"row":""}},[_c('v-select',{staticStyle:{"width":"324px"},attrs:{"items":_vm.catalogfamilyFilter,"item-text":"family","item-value":"familyId","label":_vm.$t('catalog.familyFilterName'),"chips":"","multiple":"","clearable":""},model:{value:(_vm.familyFilter),callback:function ($$v) {_vm.familyFilter=$$v},expression:"familyFilter"}})],1),_c('v-row',{staticStyle:{"width":"324px"}},[_c('v-btn',{attrs:{"rounded":_vm.$appConfig.hasOwnProperty('button')
                          ? _vm.$appConfig.button.rounded
                          : false,"color":"primary","dark":""},on:{"click":_vm.filter}},[_c('v-icon',[_vm._v(" mdi-filter ")]),_vm._v(" "+_vm._s(_vm.$t("common.filter"))+" ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","dark":"","rounded":_vm.$appConfig.hasOwnProperty('button')
                          ? _vm.$appConfig.button.rounded
                          : false},on:{"click":_vm.clearFilter}},[_c('v-icon',[_vm._v(" mdi-filter-off ")]),_vm._v(" "+_vm._s(_vm.$t("common.filterClear"))+" ")],1)],1)],1)],1)],1)],1)],1),(this.isLoading == true)?_c('v-row',{staticClass:"ma-0 pa-0 justify-center"},_vm._l((7),function(n){return _c('v-flex',{key:n},[_c('v-skeleton-loader',_vm._b({staticClass:"mx-auto mt-5 mb-2",attrs:{"max-width":"344","min-width":"344","height":"400","max-height":"500","type":"card, article, actions"}},'v-skeleton-loader',_vm.attrs,false))],1)}),1):_vm._e(),(this.isLoading == false)?_c('v-row',{staticClass:"ma-0 pa-0 justify-center"},_vm._l((_vm.filteredCatalog),function(item,productCode){return _c('v-flex',{key:productCode},[_c('v-card',{staticClass:"mx-auto mt-5 mb-2",attrs:{"flat":"","hover":"","max-width":"344","min-width":"344","height":"400","max-height":"500"}},[_c('v-hover',{staticStyle:{"background-color":"white"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-img',{attrs:{"height":"250","contain":"","src":item.imageUrl}},[_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":_vm.$appConfig.bgHover}},[[_c('v-btn',{attrs:{"rounded":_vm.$appConfig.hasOwnProperty('button')
                                ? _vm.$appConfig.button.rounded
                                : false,"color":"primary","dark":""},on:{"click":function($event){$event.stopPropagation();{
                                _vm.selectedProduct = item;
                                _vm.dialog = true;
                              }}}},[_vm._v(" "+_vm._s(_vm.$t("catalog.details"))+" ")])]],2):_vm._e()],1)],1)]}}],null,true)}),_c('v-card-title',{staticClass:"justify-center primary--text",staticStyle:{"font-size":"1rem"}},[_vm._v(" "+_vm._s(item.name.length > 60 ? item.name.slice(0, 60) + "..." : item.name)+" ")]),_c('v-card-actions',{staticClass:"justify-center"},[[_c('v-btn',{attrs:{"color":"primary","min-width":"300px","rounded":_vm.$appConfig.hasOwnProperty('button')
                        ? _vm.$appConfig.button.rounded
                        : false},on:{"click":function($event){return _vm.redeem(item)}}},[_vm._v(" "+_vm._s(_vm.$t("catalog.productRedeem"))+" ")])]],2)],1)],1)}),1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v(_vm._s(_vm.selectedProduct.name))]),_c('v-card-text',[_c('v-img',{staticClass:"ma-6",attrs:{"height":"250","contain":"","src":_vm.selectedProduct.imageUrl}}),_c('v-row',{staticClass:"mx-0",attrs:{"justify":"end"}},[_c('div',{staticClass:"my-4 subtitle"},[_vm._v(" REF: "+_vm._s(_vm.selectedProduct.productCode)+" ")])]),_c('p',{staticClass:"my-4 subtitle"},[_vm._v(" "+_vm._s(_vm.selectedProduct.description)+" ")])],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"primary","rounded":_vm.$appConfig.hasOwnProperty('button')
                      ? _vm.$appConfig.button.rounded
                      : false},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("common.close")))])],1)],1)]],2)],1),_c('FlashMessage',{attrs:{"position":'right top'}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }